<ng-sidebar-container>
    <!-- A sidebar MENU -->
    <ng-sidebar [(opened)]="_opened" [(mode)]="_sidebarMode">
        <div class="container-fluid" *ngIf="_opened">
            <div class="row mb-5 no-gutters" id="sidebarTitle">
                <div class="col-12 text-center mt-2">
                    <div class="border-bottom">
                        <img src="./../../assets/img/GMC.png" alt="GMC" class="img-fluid" />
                        <p>
                            {{ appService.Data.currentUser }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" *ngFor="let option of appService.Data.menu; index as i"
                    (click)="onClickMenuOption(option)">
                    <div class="row">
                        <div class="col-12 menu-option" [ngClass]="{'collapsed': !option.active}" data-toggle="collapse" *ngIf="option.havePerm">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2">
                                            <i class="fa-lg mr-4" [ngClass]="[option.iconClass]"></i>
                                        </div>
                                        <div class="col-10">{{ option.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 collapse" [ngClass]="{'show': option.active}" *ngIf="option.havePerm && option.submenu && option.submenu.length > 0" [attr.id]="'subMenu-' + i">
                            <div class="row menu-option" *ngFor="let subMenu of option.submenu" [hidden]="!subMenu.havePerm"
                                (click)="onClickSubMenuOption(subMenu);$event.stopPropagation();">
                                <div class="col-10 offset-2" *ngIf="subMenu.havePerm">
                                    <!-- {{ subMenu.name }} -->
                                    <div class="row">
                                        <div class="col-2">
                                            <i class="fa-lg mr-4" [ngClass]="[subMenu.iconClass]"></i>
                                        </div>
                                        <div class="col-10">{{ subMenu.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-sidebar>

    <!-- Page content -->
    <div ng-sidebar-content>
        <!-- <button (click)="_toggleSidebar()">Toggle sidebar</button> -->
        <div class="container-fluid p-0">
            <app-header></app-header>
            <div id="router-outlet-container" class="pb-1 pt-3 outlet-container-padding">
                <router-outlet></router-outlet>
            </div>
        </div>

    </div>



</ng-sidebar-container>