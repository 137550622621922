import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataStorageService } from './data-storage.service';


@Injectable({
  providedIn: 'root'
})
export class SectionService {

  currentSection = 'Inicio';
  selectedCompany = '';

  constructor(
    private router: Router,
    private dataStorageService: DataStorageService
    ) {
    console.log('SectionService');
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.defineTitleBasedOnURL(event.url);
        }
      });

    console.log('sectionService');
  }

  defineTitleBasedOnURL(URL: string) {
    let splitURL = URL.split('/');

    switch (splitURL[1]) {
      case '':
      case 'Home':
        this.currentSection = 'Inicio';
        break;
      case 'SelectCompany':
        this.currentSection = 'Seleccionar compañía';
        break;
      case 'SelectDocuments':
        this.currentSection = 'Seleccionar documentos';
        break;
      case 'SearchPruchaseOrders':
        this.currentSection = 'Creación de facturas de compra';
        break;
      case 'Lots':
        this.currentSection = 'Lotes';
        break;
      case 'Reports':
        this.currentSection = 'Reportes';
        break;
      case 'ConfigCtaBanks':
        this.currentSection = 'Configuración cuentas bancarias';
        break;
      case 'ConfigEmails':
        this.currentSection = 'Configuración correos';
        break;
      case 'ConfigCompany':
        this.currentSection = 'Configuración compañía';
        break;
      case 'ConfigPerms':
        this.currentSection = 'Configuración permisos';
        break;
      case 'SAPCredentials':
        this.currentSection = 'Credenciales de SAP';
        break;
      case 'ConfigReports':
        this.currentSection = 'Reportes de ordenes de compra';
        break;
      default:
        break;
    }
  }
}
