import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompaniasModel } from '../intefaces/companys';
import { AppConstants } from '../intefaces/constants';
import { BaseResponse, CompaniesResponse, GetSAPConnectionsResponse, GenericResponse } from '../intefaces/response';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  // metodo que obtiene las companias registradas en la DB
  GetCompanies(OnlyActives: boolean) {
    return this.http.get<CompaniesResponse>(`${environment.ApiUrl}api/Company/GetCompanies?OnlyActives=${OnlyActives}`);
  }

  // metodo que obtiene listado de conexiones de SAP
  GetSapConnections() {
    return this.http.get<GetSAPConnectionsResponse>(`${environment.ApiUrl}api/Company/GetSapConnections`);
  }

  // metodo que crea un usuario en la DBLocal
  CreateCompany(CompanyObj: CompaniasModel) {
    return this.http.post<BaseResponse>(`${environment.ApiUrl}api/Company/CreateCompany`, CompanyObj);
  }

  // metodo que actualiza un usuario en la DBLocal
  UpdateCompany(CompanyObj: CompaniasModel) {
    return this.http.post<BaseResponse>(`${environment.ApiUrl}api/Company/UpdateCompany`, CompanyObj);
  }

  GetKeyReportManager() {
    return this.http.get<GenericResponse<number>>(`${environment.ApiUrl}api/Company/GetKeyReportManager`);
  }

}
