export class AppConstants {
  // API DEBUG DANIEL
   static API_URL: string = 'http://localhost:53578/';
  // API TEST
  // static API_URL: string = 'https://mishthapitest.clavisco.com/';
  // API DEV
  // static API_URL: string = 'https://mishthapidev.clavisco.com/';
  // API PROD
  //static API_URL: string = 'https://h2hapi.clavisco.com/';

  public static get RPTMANAGER_URL(): string {
    //return "https://rptmanagertest.clavisco.com/";
   // return "https://rptmanagerdev.clavisco.com/";
   return "https://rptmanager.clavisco.com/";
  //  return "http://localhost:50050/";

  }
  public static KeyReportManager: number;

}

export enum DocumentTypes{
  GoodReceipt = 2,
  PurchaseOrder = 1
}


export interface EnumList {
  id: number;
  name: string;
}
