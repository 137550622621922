import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmMessage } from 'src/app/core/intefaces/messages';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  sms: string = ''; // mensaje de la modal
  title: string = ''; // titulo del modal

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: ConfirmMessage) { }

  ngOnInit(): void {
    this.title = this.message.title;
    this.sms = this.message.sms;
  }
}
