import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { EnumList } from '../intefaces/constants';
import { SubMenu } from '../intefaces/menu';
import { PermsModel } from '../intefaces/perms';
import { AppService } from './app.service';
import { DataStorageService } from './data-storage.service';
import { RptManagerService } from './rpt-manager/rpt-manager.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    private appService: AppService,
    private rptManagerService: RptManagerService,
    private dataStorageService: DataStorageService
  ) { }

  ConvertEnumIntoList(enumType: any): EnumList[] {
    let map: EnumList[] = [];

    for (var n in enumType) {
      if (typeof enumType[n] === 'number') {
        map.push({ id: <any>enumType[n], name: n });
      }
    }

    return map;
  }

  isNumber(x: any): x is number {
    return typeof x === "number";
  }

  isString(x: any): x is string {
    return typeof x === "string";
  }

  isUndefined(x: any): x is undefined {
    return typeof x === "undefined";
  }

  setMenuOptions(permList: PermsModel[]) {
    console.log('menu global');
    // this.appService.Data.menu = [];
    this.appService.getMenu().subscribe(data => {
      //Solo recarga el menú si no se ha cargado, evita que se cierren los submenu
      if (this.appService.Data.menu.length <= 0) this.appService.Data.menu = data;

      // mapeo de los submenus de reportes (dinamicamente)
      if (this.appService.Data.menu.length > 0 && this.appService.Data.Reports.length > 0) {

        let subMenu: SubMenu[] = [];
        this.appService.Data.Reports.forEach(element => {
          subMenu.push({
            name: element.DisplayName,
            iconClass: 'fa fa-file',
            url: `Reports-Print/${element.Id}`,
            perm: "MISHTH_Reports",
            havePerm: false
          });
        });

        let index = this.appService.Data.menu.findIndex(x => x.url === 'Reports-Print');
        this.appService.Data.menu[index].submenu = subMenu;

        //this.appService.Data.menu.find(x => x.url === 'Reports-Print')?.submenu = subMenu;
        // { "name": "Correos", "iconClass": "fa fa-envelope-o", "url": "ConfigEmails", "perm": "MISHTH_ConfigEmails", "havePerm": false },

      };


      // evaluamos los permisos para mostrarlos segun su diponibilidad a cada usuario
      if (permList.length > 0) {
        permList.forEach((perm: PermsModel) => {
          this.appService.Data.menu.filter(x => x.perm === perm.PermName).map(x => x.havePerm = true);

          this.appService.Data.menu.forEach(element => {
            if (element.submenu && element.submenu.length > 0) {
              element.submenu.filter(x => x.perm === perm.PermName).map(x => x.havePerm = true);
            }
          });

        });
      }
    });
  }

  // 0 = Sunday, 1 = Monday, ...
  getNextWeekDay(d: number) {
    var next = new Date();
    next.setDate(next.getDate() - next.getDay() + 7 + d);
    return next;
  }

  getReports() {
    this.rptManagerService
      .GetReports()
      .pipe(first())
      .subscribe(
        (response) => {
          console.log(response);
          this.appService.Data.Reports.length = 0;
          if (response.Result) {
            this.appService.Data.Reports = response.Reports;
            let currentPerms = JSON.parse(this.dataStorageService.getStorage('HTHPerms'));
            this.setMenuOptions(currentPerms);  
          } else {
            console.log(response.ErrorInfo.Message)
          };
        },
        (err) => {
          console.log(err);
        }
      );
  }

}
