<header class="row header sticky-top m-0 shadow-sm">
  <div fxFlex="70" fxFlex.gt-sm="70" fxFlex.sm="60">
    <div class="row pl-3">
      <button type="button" [title]="sidebarState ? 'Cerrar menú' : 'Abrir menú'" class="btn mx-1 my-2" (click)="toggleSidebar()">
        <i [ngClass]="{'fa-bars': !sidebarState, 'fa-chevron-left': sidebarState}" class="fa fa-lg" aria-hidden="true"></i>
      </button>
      <div class="pt-1">
        <h4 class="mt-2">{{ sectionService.currentSection }}</h4>
      </div>
    </div>
  </div>
  <div fxFlex="30" fxFlex.gt-sm="30" fxFlex.sm="40" class="text-right">
    <h4 class="mt-2 p-1">{{ selectedCompany }}</h4>
  </div>
</header>