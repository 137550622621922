import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AppConstants } from '../core/intefaces/constants';
import { AppService } from '../core/services/app.service';
import { AuthenticationService } from '../core/services/authentication.service';
import { CompanyService } from '../core/services/company.service';
import { GlobalService } from '../core/services/global.service';
import { SidebarService } from '../core/services/sidebar.service';

// models
import { Menu, SubMenu } from './../core/intefaces/menu';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit, OnDestroy {
  _menu: Menu[] = [];
  _opened: boolean = false;
  _sidebarMode: any = 'push';
  menu: any;

  constructor(
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appService: AppService,
    private sidebarService: SidebarService,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private globalService: GlobalService
  ) {
    this.sidebarService.toggler.subscribe((toggle) => (this._opened  = toggle));
  }

  ngOnInit(): void {
    console.log('ngOnInit pages');
    this.onLoad();
  }

  ngOnDestroy(): void {
  }

  public _toggleSidebar() {
    this._opened = !this._opened;
  }

  onLoad() {
    // this.generateMenu();
  }

  // generateMenu() {
  //   if (this.appService.Data.menu && this.appService.Data.menu.length > 0) {
  //     // this._menu = this.appService.Data.menu;
  //   } else {
  //     this.appService.getMenu().subscribe(data => {
  //       this.appService.Data.menu = data;
  //     });
  //   }
  // }

  onClickSubMenuOption(url: SubMenu) {
    this.router.navigateByUrl(url.url);
  }

  onClickMenuOption(option: Menu) {
    console.log('option');
    console.log(option);
    console.log('appService.Data.menu');
    console.log(this.appService.Data.menu);
    this.appService.Data.menu.forEach((x) => {if(x != option) x.active = false});
    this._menu.forEach((x) => (x.active = false));
    if (option.name === 'Salir') {
      this.authService.logout();
      window.location.href = ('https://appgm.clavisco.com/MIS-Principal/Home/Index');
    } else {
      option.active = !option.active;
    }
    if (option.url !== '' && option.url !== 'Reports-Print') {
      this.router.navigateByUrl('/' + option.url);
    }
    if (option.url !== '' && option.url === 'Reports-Print') {
      this.appService.Data.Reports.length = 0;
      let index = this.appService.Data.menu.findIndex(x => x.url === 'Reports-Print');
        this.appService.Data.menu[index].submenu = [];
      this.getReports();
    }
  }

  getReports() {
    console.log('getReport');
    let reports = [];
    
    if (!AppConstants.KeyReportManager) {
      this.companyService
        .GetKeyReportManager()
        .pipe(first())
        .subscribe((response) => {
          if (response.Result) {
            AppConstants.KeyReportManager = response.Data;
            this.globalService.getReports();
            // this.getReportUsers();
            // this.getReports();
          } else {
            // this.alertService.errorAlert(
            //   "Ocurrió un error obteniendo la configuración de reportes de la compañía"
            // );
          }
        });
    } else {
      this.globalService.getReports();
      // this.getReportUsers();
      // this.getReports();
    }
  }



}