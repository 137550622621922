import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  private localStorageS;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor() {
    this.localStorageS = localStorage;
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.getStorage('HTHSelectedCompany')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public deleteCurrentUserValue(): any {
    this.currentUserSubject.next(null);
  }


  setStorage(name: string, obj: any): void {
    this.localStorageS.setItem(name, JSON.stringify(obj));
  }

  deleteStorage(name: string): void {
    this.localStorageS.removeItem(name);
  }

  getStorage(name: string): any {
    return this.localStorageS.getItem(name);
  }

}
