import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConstants } from './../intefaces/constants';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataStorageService } from './data-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermsModel } from '../intefaces/perms';
import { Credentials } from '../intefaces/auth'
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // private currentUserSubject: BehaviorSubject<any>;
  // public currentUser: Observable<any>;
  isSidebarVisible: boolean = false;
  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient,
    private dataStorageService: DataStorageService,
    private router: Router,
    public snackBar: MatSnackBar
  ) {
    console.log('authservice');
  }

  toggleSidebarVisibility() {
    this.sidebarVisibilityChange.next(!this.isSidebarVisible);
  }
  // funcion para el logueo del usuario,
  // recibe como parametros el userId
  logIn(userId: string) {

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('UserName', userId);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<any>(`${environment.ApiUrl}token`, body.toString(), { headers });
  }

  logout() {
    this.dataStorageService.deleteStorage('HTHCurrentUser');
    this.dataStorageService.deleteStorage('HTHPerms');
    this.dataStorageService.deleteStorage('HTHSelectedCompany');
    this.router.navigate(['']);
  }

  cantAccess(path: string) {
    this.router.navigate([path]);
  }

  // Verificacion si un usuario esta logueado y el token no ha expirado aun
  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    // const TOKEN = this.currentUserValue;
    const TOKEN = JSON.parse(this.dataStorageService.getStorage('HTHCurrentUser'));

    if (TOKEN) {
      var expires = new Date(TOKEN.ExpireTime);
      var today = new Date();
      if (today <= expires) { // posee token y el mismo aun no expira, es valido
        return true;
      }
    }
    return false;
  }

  havePerm(perm: string): boolean {
    let currentPerms = JSON.parse(this.dataStorageService.getStorage('HTHPerms'));

    let havePerm = currentPerms.filter((x: PermsModel) => {
      return x.PermName === perm
    });

    if (havePerm.length > 0) { // tiene permiso para ingresar a la pagina deseada
      return true;
    } else { // no tiene permiso para ingresar a la pagina deseada
      return false;
    }
  }

  haveSelectedCompany(): boolean {
    let selectedCompany = JSON.parse(this.dataStorageService.getStorage('HTHSelectedCompany'));
    if (selectedCompany) {
      return true;
    } else {
      return false;
    }

  }

  // verificar usuario ingresado cuando se va a crear el lote
  CheckUser(Email: string, PassWord: string) {
    let Credentials: Credentials = {
      Email: Email,
      Password: PassWord
    };
    return this.http.post<boolean>(`${environment.ApiUrl}api/Auth/CheckUser`, Credentials);
  }

}
