import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { DataStorageService } from '../services/data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    public auth: AuthenticationService,
    public snackBar: MatSnackBar,
    ) {
      console.log('AuthGuard');
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.auth.isAuthenticated()) {
      this.auth.logout();
      return false;
    }

    if (route!.routeConfig!.path !== 'SelectCompany' && !this.auth.haveSelectedCompany()) {
      this.auth.cantAccess('SelectCompany');
      this.snackBar.open(`Debe de seleccionar una compañía para continuar, gracias!!!`, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return false;
    }
    
    // validacion de permisos
    if (this.auth.havePerm('MISHTH_SU')) { // si tiene el permiso de super user lo deja pasar a todo lado
      return true;
    }
    if (route!.routeConfig!.path === 'SelectCompany' && !this.auth.havePerm('MISHTH_SelectCompany')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'SelectDocuments' && !this.auth.havePerm('MISHTH_SelectDocuments')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'Lots' && !this.auth.havePerm('MISHTH_Lots')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'Reports' && !this.auth.havePerm('MISHTH_Reports')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'ConfigCtaBanks' && !this.auth.havePerm('MISHTH_ConfigCtaBanks')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'ConfigEmails' && !this.auth.havePerm('MISHTH_ConfigEmails')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'ConfigCompany' && !this.auth.havePerm('MISHTH_ConfigCompany')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'ConfigPerms' && !this.auth.havePerm('MISHTH_ConfigPerms')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'SAPCredentials' && !this.auth.havePerm('MISHTH_ConfigUsers')) {
      this.auth.cantAccess('');
      return false;
    }
    if (route!.routeConfig!.path === 'SearchPruchaseOrders' && !this.auth.havePerm('MISHTH_SearchPruchaseOrders')) {
      this.auth.cantAccess('');
      return false;
    }
    
    
    return true;
  }
  
}
