import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { EnumList } from '../intefaces/constants';
import { IUserProfile } from '../intefaces/i-user-profile';
import { Menu } from '../intefaces/menu';
import { Report2 } from '../intefaces/rpt-manager/i-report';

export class Data {
  constructor(public menu: Menu[],
              public currentUser: string,
              public Reports: Report2[]) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public Data = new Data(
    [], // menuList
    '', // current user
    [] // reportes
  )
  public url = "assets/data/";
  constructor(public http: HttpClient, public snackBar: MatSnackBar) { }

  public getMenu(): Observable<Menu[]> {
    return this.http.get<Menu[]>(this.url + 'menu.json');
  }


  public getPlanTypes(): Observable<EnumList[]> {
    return this.http.get<EnumList[]>(this.url + 'planTypes.json');
  }

  public getCurrencyTypes(): Observable<EnumList[]> {
    return this.http.get<EnumList[]>(this.url + 'currencyTypes.json');
  }

  public getPurchaseOrderStatus(): Observable<EnumList[]> {
    return this.http.get<EnumList[]>(this.url + 'purchaseOrderStatus.json');
  }

  public getLoteStatus(): Observable<EnumList[]> {
    return this.http.get<EnumList[]>(this.url + 'loteStatus.json');
  }

  public getTransactionLoteStatus(): Observable<EnumList[]> {
    return this.http.get<EnumList[]>(this.url + 'transactionLoteStatus.json');
  }

  public getUserProfiles(): Observable<EnumList[]> {
    return this.http.get<EnumList[]>(this.url + 'userProfiles.json');
  }

  public getUProfiles():Observable<IUserProfile[]>{
    return this.http.get<IUserProfile[]>(this.url + 'userProfiles.json');
  }

}
